import localizeFilter from '@/filter/localize.filter'
export default function getAvgMarks(){
    //console.log(this.$data.selectedMark)
    this.$data.avgMarks = [];

    this.$Progress.start()
    axios.get('/search.php',
        {
            params:
            {
                type: 5
            }
        })
        .then(response => {
            if(response.data != -10){
                this.$data.avgMarks = response.data;
                this.$data.avgMarksAll = response.data;

            }else
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            this.$Progress.finish()
        })
        .catch(error =>{
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            this.$Progress.fail()
        })
}
