<template>
    <div>
        <!--
        <div class="uk-inline uk-width-expand">
            <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                <input class="uk-input uk-width-1-1 search-fld" type="text" v-model="val" :placeholder="localizeFilter('Search')" @input="filter(val)">
        </div>
        -->
        <div class="uk-margin-small-top" style="">
            <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;" id=my-id>
                <caption>Все цены указаны за 1 кг !</caption>
                <thead>
                    <tr>
                        <th style="position: sticky; top:80px; background:white;">Название</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Max Pt</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Min Pt</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Avg Pt</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Max Pd</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Min Pd</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Avg Pd</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Max Rh</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Min Rh</th>
                        <th style="position: sticky; top:80px; background:white;" class="uk-text-nowrap" >Avg Rh</th>
                    </tr>
                </thead>
                <tbody  v-for="(result, name) in avgMarks"  :id="result.idmark_auto"  >
                    <tr @click="toggle(result.idmark_auto)" :class="{ opened: opened.includes(result.idmark_auto) }" style="cursor: pointer" uk-tooltip="title: Нажмите для просмотра моделей; pos: top; delay: 300">
                        <td>{{result.mark_auto}}</td>
                        <td class="uk-text-nowrap">{{result.max_pt || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result.min_pt || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result.avg_pt || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap">{{result.max_pd || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result.min_pd || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result.avg_pd || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap">{{result.max_rh || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result.min_rh || 0 | changeToDecimal }}</td>
                        <td class="uk-text-nowrap" >{{result.avg_rh || 0 | changeToDecimal}}</td>
                    </tr>
                    <tr v-if="opened.includes(result.idmark_auto)" style="border-left:70px solid transparent; background: rgb(232, 232, 232); border-top: 1px solid #bbb;" v-for="(result2, name2) in result.models" >
                        <td>{{result2.model_name}}</td>
                        <td class="uk-text-nowrap">{{result2.max_pt || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result2.min_pt || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result2.avg_pt || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap">{{result2.max_pd || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result2.min_pd || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result2.avg_pd || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap">{{result2.max_rh || 0 | changeToDecimal}}</td>
                        <td class="uk-text-nowrap" >{{result2.min_rh || 0 | changeToDecimal }}</td>
                        <td class="uk-text-nowrap" >{{result2.avg_rh || 0 | changeToDecimal}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>

import {eventBus} from '@/main'
import localizeFilter from '@/filter/localize.filter'
import changeToDecimal from "@/filter/dec.filter"
import store from '@/store'
import getAvgMarks from '@/config/GetAverageMarksMetal.general'

export default {
    metaInfo:{
        title: localizeFilter('TitleAverageM')
    },
    data: () => ({
        avgMarks: '',
        avgMarksAll:'',
        opened : [],
        role: $cookies.get('role'),
        val: ''
    }),
    methods:{
        getAvgMarks,
        localizeFilter,
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
        }
    }
    },
    beforeMount(){
        this.getAvgMarks();
    },
    created(){
        eventBus.$on('gofilter', data => {
            if(this.avgMarksAll != ''){
                var f = this.avgMarksAll
                this.avgMarks = f.filter( function(i){
                    if(i['mark_auto'] === undefined || i['mark_auto'] != data['mark'])
                        return false
                    return true
                })
            }

            eventBus.$on('clearfilter', () => {
                this.avgMarks = this.avgMarksAll
            })
        })
    },
    filters:{
        changeToDecimal
    }
}
</script>
